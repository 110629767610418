<template>
  <auth-layout>
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-card
        :max-width="550"
        :width="500"
        rounded="lg"
        :loading="loading"
        outlined
        color="background"
      >
        <v-card-title class="textPrimary--text text-h4 font-weight-semibold">{{
          $t('auth.forgotPassword')
        }}</v-card-title>
        <v-card-text v-if="resetPasswordSent">
          <div>{{ $t('auth.resetPasswordSentMessage') }}</div>
          <cz-button
            class="mt-4 text-body-1 font-weight-medium"
            :title="$t('auth.backToLogin')"
            :icon-src="mdiArrowRight"
            color="textPrimary"
            @click="
              $router.replace({
                name: 'login'
              })
            "
          />
        </v-card-text>
        <v-card-text v-else>
          <v-form @submit.prevent="onSubmit" :disabled="loading">
            <v-card-text>
              <cz-form-field
                large
                required
                :label="$t('auth.email')"
                :icon-src="mdiEmailOutline"
              >
                <validation-provider
                  rules="required|email"
                  :name="$t('auth.email')"
                  v-slot="{ errors }"
                >
                  <cz-input
                    v-model="email"
                    :error-messages="errors"
                    :placeholder="$t('auth.enterLoginEmail')"
                  />
                </validation-provider>
              </cz-form-field>

              <v-fade-transition>
                <v-alert type="error" v-if="errorMessage">{{
                  errorMessage
                }}</v-alert>
              </v-fade-transition>
            </v-card-text>
            <v-card-actions class="d-flex flex-column" style="row-gap: 20px">
              <cz-button
                :title="$t('auth.resetPassword')"
                color="button"
                x-large
                type="submit"
                :loading="loading"
                :disabled="invalid"
                :dark="!invalid"
                class="full-width font-weight-semibold"
              />
              <div class="d-flex full-width justify-space-between">
                <cz-button
                  :title="$t('auth.backToLogin')"
                  :icon-src="mdiArrowRight"
                  class="text-body-1 font-weight-medium"
                  text
                  color="textPrimary"
                  :to="{
                    name: 'login'
                  }"
                />
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <cz-button
                      :title="$t('auth.forgotEmail')"
                      text
                      v-on="on"
                      v-bind="attrs"
                      color="textPrimary"
                      class="text-body-1 font-weight-medium"
                      :icon-src="mdiInformationOutline"
                    />
                  </template>
                  <v-card :width="250">
                    <v-card-text class="text-pre-wrap">
                      {{ $t('auth.forgotEmailInfo') }}
                      <a href="mailto://hello@corz.app">hello@corz.app</a>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </validation-observer>
  </auth-layout>
</template>

<script>
import bg from '@/assets/background-pattern.jpg';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { CzFormField, CzInput, CzButton } from '@/components';
import { mapActions } from 'vuex';
import { sentResetPassword } from '@/core/api';
import { mdiEmailOutline, mdiInformationOutline, mdiArrowRight } from '@mdi/js';
export default {
  name: 'ResetPassword',
  components: {
    AuthLayout,
    CzFormField,
    CzInput,
    CzButton
  },
  data() {
    return {
      mdiEmailOutline,
      mdiInformationOutline,
      mdiArrowRight,
      bg,
      loading: false,
      resetPasswordSent: false,
      email: '',
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async onSubmit() {
      let recapchaToken = undefined;
      if (process.env.VUE_APP_RECAPCHA_ENABLED === 'true') {
        await this.$recaptchaLoaded();
        recapchaToken = await this.$recaptcha('login');
      }

      try {
        this.loading = true;
        await sentResetPassword(this.email, recapchaToken);
        this.resetPasswordSent = true;
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
